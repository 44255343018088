import axios, { formatFormData } from '../utils/axios';

// application
export const getAppList = () => axios({ url: '/admin/client/list', method: 'post' });
export const deleteApp = (data) => axios({ url: '/admin/client/delete', method: 'post', data: formatFormData(data) });
export const createApp = (data) => axios({ url: '/admin/client/create', method: 'post', data: formatFormData(data) });

export const createModule = (data) => axios({ url: '/admin/client/addModule', method: 'post', data: formatFormData(data) });
export const deleteModule = (data) => axios({ url: '/admin/client/deleteModule', method: 'post', data: formatFormData(data) });
export const updateAppModule = (data) => axios({ url: '/admin/client/updateModule', method: 'post', data });

// user
export const getUserList = (data) => axios({ url: '/admin/user/list', method: 'post', data: formatFormData(data) });
export const updateUserApp = (data) => axios({ url: '/admin/user/updateClient', method: 'post', data })
