import { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import UserContext from '../UserContext';
import { ROUTES } from '../app.config';

export default () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [userInfo] = useContext(UserContext);
  useEffect(() => {
    if (userInfo) {
      if (ROUTES.login.indexOf(pathname) === -1 && pathname !== '/' && ROUTES.beforeLogin.indexOf(pathname) !== -1) {
        history.push('/');
      }
    } else {
      if (ROUTES.beforeLogin.indexOf(pathname) === -1 && pathname !== '/login' && ROUTES.login.indexOf(pathname) !== -1) {
        history.push('/login');
      }
    }
  })
}