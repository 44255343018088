import axios from 'axios';
import { message } from 'antd';
import { baseURL } from '../app.config';

let axiosIns = axios.create({
  baseURL
});

axiosIns.defaults.withCredentials = true;

axiosIns.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
});

axiosIns.interceptors.response.use(response => {
  if (response.status === 200) {
    return response.data;
  } else {
    message.error(response.statusText);
    return Promise.reject();
  }
}, error => {
  return Promise.reject(error);
});

export function formatFormData(obj) {
  let fd = new FormData();
  for (let key in obj) {
    if (obj[key] !== undefined && obj[key] !== null) {
      fd.append(key, obj[key])
    }
  }
  return fd;
}

export default axiosIns;