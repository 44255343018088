import { message } from 'antd';
import axios, { formatFormData } from '../utils/axios';
import { baseURL, ROUTES, REDIRECT_URL } from '../app.config';
import history from '../history';

// 登录
export const login = (data) =>
  axios({ url: '/security_check', method: 'post', data: formatFormData(data) }).then(({ status }) => {
    if (status === 200) {
      message.success('Login success');

      // 重定向到第三方应用
      return new Promise((resolve) => {
        let params = localStorage.getItem(REDIRECT_URL);
        if (params) {
          localStorage.removeItem(REDIRECT_URL);
          window.location.href = baseURL + '/oauth/authorize' + params;
        } else {
          resolve();
        }
      })
    } else {
      message.error('Login error');
      return Promise.reject();
    }
  });

// 退出登录
export const logout = () => axios({ url: '/user/logout', method: 'post' });

// 获取用户信息
export const getUserInfo = () =>
  axios({ url: '/user/profile', method: 'post' }).then(
    ({ status, data }) => {
      const { location: { pathname } } = history;
      if (status === 200) {
        if (ROUTES.beforeLogin.indexOf(pathname) !== -1) {
          history.push('/');
        }
        return Promise.resolve(data);
      } else {
        if (ROUTES.login.indexOf(pathname) !== -1) {
          history.push('/login');
        }
        return Promise.reject();
      }
    }
  );

// 注册
export const register = (data) => axios({ url: '/register', method: 'post', data: formatFormData(data) });

// 忘记密码
export const resetPasswordConfirmEmail = (data) =>
  axios({ url: '/resetPasswordRequest', method: 'post', data: formatFormData(data) });
export const resetPassword = (data) => axios({ url: '/resetPassword', method: 'post', data: formatFormData(data) });

// 修改邮箱、修改密码
export const changeEmail = (email) => axios({ url: `/user/resetEmail?email=${email}`, method: 'post' });
export const changePassword = (password) =>
  axios({ url: `/user/changePassword?password=${password}`, method: 'post' });

// 上传头像
export const uploadAvatar = (file) =>
  axios({ url: '/user/avatar', method: 'post', data: formatFormData({ avatar: file }) })

// 修改基本信息
export const updateProfile = (data) => axios({ url: '/user/updateProfile', method: 'post', data: formatFormData(data) })