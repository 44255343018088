const isDev = process.env.NODE_ENV === "development";

// const origin = window.location.origin;
// export const baseURL = isDev ? 'http://localhost:8000' : 'http://202.120.188.239:8000';
export const baseURL = isDev ? 'http://localhost:8000' : 'https://service.datacalliope.com:8001';
//"proxy": "http://47.111.254.21:8080/"

export const BASIC_TOKEN = 'basic_token';

export const ROUTES = {
  beforeLogin: ['/login', '/sign-up', '/register-confirm', '/reset-password', '/confirm-email'],
  login: ['/', '/user-profile', '/change-email', '/change-password', '/confirm-email']
}

export const REDIRECT_URL = '@@redirect_url';