import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Router, Link, Route, Switch } from 'react-router-dom';
import { Layout, message } from 'antd';

import history from './history';
import UserContext from './UserContext';
import { getUserInfo, logout } from './api';
import { getRedirectUriParams } from './utils/url-param';

import Root from './pages/Root';
import { REDIRECT_URL } from './app.config';

import logo from './images/calliope-logo.png';

const Login = lazy(() => import('./pages/Login'));

// 注册、注册确认邮件
const SignUp = lazy(() => import('./pages/SignUp'));
const RegisterConfirm = lazy(() => import('./pages/RegisterConfirm'));

// 忘记密码
const ConfirmEmail = lazy(() => import('./pages/ConfirmEmail'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

// 修改邮箱修改密码
const UserProfile = lazy(() => import('./pages/UserProfile'));
const ChangeEmail = lazy(() => import('./pages/ChangeEmail'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));

// 管理员
const Admin = lazy(() => import('./pages/Admin/index'));

const loading = (
  <div>loading...</div>
)

const { Header, Content } = Layout;

export default () => {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    getUserInfo().then(data => setUserInfo(data));
  }, []);

  let params = getRedirectUriParams();
  if (params.length > 0) {
    localStorage.setItem(REDIRECT_URL, params);
  }

  const handleLogout = () => {
    logout().then(({ status, message: msg }) => {
      if (status === 200) {
        setTimeout(() => {
          history.push('/login');
          setUserInfo(null);
        }, 500)
      } else {
        message.info(msg);
      }
    })
  }

  return (
    <UserContext.Provider value={[userInfo, setUserInfo]}>
      <Router history={history}>
        <Layout className='__user-layout'>
          <Header className='__user-header'>
            <div className='__user-header-title'>
              <Link to='/'>
                <img src={logo} alt="" />
                <span className='title'>Calliope·Data</span>
              </Link>
            </div>
            <div className='__user-header-operation'>
              {userInfo ? userInfo.fullName : <Link to='/login'>Log in</Link>}&emsp;|&emsp;
              {userInfo ?
                <span style={{ cursor: 'pointer' }} onClick={handleLogout}>logout</span> :
                <Link to='/sign-up' className='__user-fillet-link'>Sign up</Link>}
            </div>
          </Header>
          <Content>
            <Suspense fallback={loading}>
              <Switch>
                <Route path='/' exact component={Root} />
                <Route path='/admin' component={Admin} />
                <Route path='/change-email' component={ChangeEmail} />
                <Route path='/change-password' component={ChangePassword} />
                <Route path='/user-profile' component={UserProfile} />
                <Route path='/login' component={Login} />
                <Route path='/sign-up' component={SignUp} />
                <Route path='/register-confirm' component={RegisterConfirm} />
                <Route path='/confirm-email' component={ConfirmEmail} />
                <Route path='/reset-password' component={ResetPassword} />
              </Switch>
            </Suspense>
          </Content>
        </Layout>
      </Router>
    </UserContext.Provider>
  )
}