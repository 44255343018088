import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import useRedirect from '../hooks/useRedirect';
import UserContext from '../UserContext';
import Title from '../components/Title';
import imgAdmin from '../images/admin-system.png';
import imgUserProfile from '../images/user-profile.png';
import imgChangePassword from '../images/change-password.png';
import imgChangeEmail from '../images/change-email.png';

function getUserRole(userInfo) {
  return userInfo && Array.isArray(userInfo.roleEntities)
    && userInfo.roleEntities.length > 0 && userInfo.roleEntities[0].roleName
    && userInfo.roleEntities[0].roleName === 'ROLE_ADMIN'
}

export default () => {
  useRedirect();
  const [userInfo] = useContext(UserContext);

  return (
    <div className='__user-wrapper'>
      <Title>Account</Title>
      <div className='__user-form-wrapper'>
        {getUserRole(userInfo) &&
          <p><Link to='/admin' className='__user-text-link'><img src={imgAdmin} alt="" />Admin System</Link></p>}
        <p><Link to='/user-profile' className='__user-text-link'><img src={imgUserProfile} alt="" /> User profile</Link></p>
        <p><Link to='/change-email' className='__user-text-link'><img src={imgChangeEmail} alt="" />Change email</Link></p>
        <p><Link to='/confirm-email' className='__user-text-link'><img src={imgChangePassword} alt="" />Change password</Link></p>
      </div>
    </div>
  )
}